@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap');

:root {
    --text-color: #f2f2f2;
    --background-color: #191a1c;
    --card-color: #0b0c0d;
    --place-color: #2c2d2e;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
    color: var(--text-color);
    box-sizing: border-box;
    margin: 0;
}

header {
    height: 400px;
    background-color: #343C42;
    margin: 0;
}

a:visited {
    text-decoration: none;
    color: #f2f2f2;
}

/* NAVIGATION MENU */

nav {
    margin: 1em 2em 0em 0em;
    float: right;
    font-size: 1.5em;
    font-weight: 500;
    margin-top: 2em;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}

nav a {
    text-decoration: none;
    color: var(--text-color);
    margin-right: 2em;
    margin-top: 2em;
}

/* Hide the navigation menu by default */
.nav-mobile-icon {
    display: none;
    cursor: pointer;
    font-size: 1.3em;
}

.logo-mobile {
    position: absolute;
    display: flex;
    height: 1.5em;
    margin: 3.2em 0em -6em 2em;
}

/* Mobile nav overlay */

.full-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #1d1e21;
    z-index: 100;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: height 0.3s ease;
}

.full-menu-content {
    display: flex;
    background-color: #1d1e21;
    width: 100%;
    padding: 1em;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5em;
}

.full-menu-overlay.open {
    height: 100%;
    /* Expand height when menu is open */
    animation: slideIn 0.3s forwards;
    /* Apply slide-in animation */
}

.full-menu-content a {
    display: block;
    text-align: center;
    color: black;
    text-decoration: none;
    margin: 1em;
    color: #f2f2f2;
}

.logo {
    width: 266px;
    position: absolute;
    margin: 3.2em 0em -6em 5.5em;
}

/* .logo-mobile {
    display: none;
} */

/* HOME PAGE */

.logobgV2 {
    width: 550px;
    margin: 35em 0em -2em 3em;
}

.tag-container {
    font-size: 6em;
    font-weight: 400;
    margin-left: 1em;
    position: absolute;
}

.tagline {
    margin-bottom: -1em;
}

.homepg-contact-details {
    display: flex;
    column-gap: 6em;
    position: absolute;
    bottom: 4em;
    margin-left: 5.2em;
}

.home-serivce-locations-container {
    margin: 5.2em;
    font-size: 1.5em;
    position: absolute;
    bottom: 4em;
    margin-bottom: -1.5em;
    right: 0em;
}

.home-serivce-locations-heading {
    margin-left: 0.9em
}

#facebook-link,
.icons {
    text-decoration: none;
    color: var(--text-color);
}

.see-our-services a {
    display: flex;
    font-size: 0.3em;
    margin-top: 3em;
    margin-left: 0.2em;
    text-decoration: none;
    color: var(--text-color);
}

.see-our-services a:hover {
    text-decoration: underline;
}

/* ABOUT US PAGE */

.about-us-blurb-container {
    display: flex;
    width: 60em;
    margin: auto;

}

.about-us-blurb {
    font-size: 1.3em;
    margin-top: 1em;
}

.our-team-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2d2d2d;
    margin-top: 2em;
}

.about-us-body {
    width: 90%;
    padding: 0.5em 0em 0em 1.8em;
    position: relative;
    z-index: 1;
    margin-right: 0em;
}

/* OUR TEAM CARDS*/

.about-us-container {
    display: flex;
    justify-content: center;
    margin-top: -7em;
}

.about-us-contents,
.about-us-contents-aiden {
    width: 25em;
    height: 13em;
    background-color: var(--card-color);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 1em;
}

.headshot {
    margin-top: 4em;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: auto;
    height: auto;
}

.about-us-blurb-card {
    box-sizing: border-box;
    margin: 0em 0em 4em -1em;
}


.will-heading,
.kelly-heading,
.aiden-heading {
    display: flex;
    position: relative;
    z-index: 1;
    margin: 0em 0em 0.3em 0.5em;
}

.will-heading {
    color: #90CAF3;
}

.kelly-heading {
    color: #77E7C0;
}

.aiden-heading {
    color: #EF86C9;
}

/* CONTACT PAGE */

.page-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    line-height: 2em;
    max-height: 11em;
}

.page-heading h2 {
    margin-bottom: 0;
}

.line2 {
    margin-top: 0em
}

/* CONTACT US CARD */

.contact-us-heading,
.success-message,
.error-message {
    margin-left: 1.1em;

}

.contact-us-heading {
    color: #6be2bb;
}

.success-message {
    color: #6be2bb;
}

.card-container {
    display: flex;
    flex-direction: column;
    margin: 5em 1em 1em 1em;
}

.card-contents {
    width: 30em;
    background-color: var(--card-color);
    border-radius: 12px;
    padding: 1em;
    box-sizing: border-box;
}

form {
    width: 100%;
    padding: 1em;
}

label {
    padding: 0.66em;
}

input {
    display: block;
    outline: none;
    border: none;
    padding: 0.4em 0.4em 0.4em 0em;
    margin: 1em 1em 1em 0.9em;
    width: 85.55%;
    background-color: var(--card-color);
    color: var(--text-color);
    border-bottom: solid var(--background-color);
    border-bottom-width: 0.2em;
}


input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--card-color) inset;
    -webkit-text-fill-color: var(--text-color);
}

textarea {
    font-family: 'IBM Plex Sans', sans-serif;
    height: 200px;
    outline: none;
    border: none;
    border-radius: 4px;
    margin: 1em 1em 1em 0.9em;
    resize: vertical;
    width: 85.55% !important;
    background-color: var(--card-color);
    color: var(--text-color);
    border-bottom: solid var(--background-color);
    border-radius: 0px;
    padding: 0.4em 0.4em 0.4em 0em;
    border-bottom-width: 0.2em;
}

textarea::placeholder,
input::placeholder {
    color: var(--place-color);
}

input:hover,
textarea:hover {
    border-bottom: solid var(--place-color);
    border-bottom-width: 0.2em;
}


.submit-btn {
    display: flex;
    width: auto;
    background-color: var(--background-color);
    border: none;
    cursor: pointer;
    color: var(--text-color);
    border-radius: 4px;
    box-shadow: none;
    padding: 0.8em;
    justify-content: center;
    text-align: center;
}

.submit-btn:hover {
    border: none;
    background-color: #6be2bb;
    color: var(--background-color);
}

/* OUR DETAILS CARD */

.our-details-heading {
    display: flex;
    margin-left: 1.1em;
    color: #EF86C9;
}

.our-deatils-body {
    width: 100%;
    padding: 1em;
}

.icons {
    margin-right: 1em;
    margin-left: 0.66em;
}

.contact-container {
    display: flex;
    justify-content: center;
}

.our-services-container {
    display: flex;
    justify-content: center;
}

.hours-container {
    display: inline-block;
}

.hours-heading {
    margin-left: 0.8em;
    margin-bottom: -1em;
}

.hours-text,
.days-text,
.public-hols-text {
    padding: 1em;
}

.hours-text {
    margin-bottom: -3em;
}

.days-text {
    margin-bottom: -1em;
}

.public-hols-text {
    font-size: 0.8em;
}

.serivce-locations-container {
    float: right;
    margin-right: 1em;
}

.serivce-locations-text {
    margin-right: 1.65em
}

.serivce-locations-heading {
    margin-left: 1.3em
}

/* OUR SERVICES CARD */

.our-services-heading {
    display: flex;
    margin-left: 1.1em;
    color: #90CAF3;
    position: relative;
    z-index: 1
}

.our-services-blurb {
    margin-bottom: 4em;
}

.our-services-list {
    margin-left: -1.3em;
    margin-bottom: 0em;
}

.service-card-contents {
    width: 25em;
    background-color: var(--card-color);
    border-radius: 12px;
    padding: 1em;
}

.our-services-body {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5em 1em 1.8em 1.8em;
    position: relative;
    z-index: 1
}

.faq-blurb {
    margin-top: 0em;
    margin-bottom: 4em;
    position: relative;
    z-index: 1
}

.faq-body {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5em 1em 1.8em 1.8em;
    position: relative;
    z-index: 1
}

.faq-card-contents {
    width: 60em;
    background-color: var(--card-color);
    border-radius: 12px;
    padding: 1em;
}

.faq-heading {
    display: flex;
    margin-left: 1.1em;
    color: #77E7C0;
    position: relative;
    z-index: 1;
}

.faq-questions {
    margin-top: 0em;
}

.brushbg {
    position: absolute;
    margin: -1em;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 432px;
}

.waterbg {
    position: absolute;
    margin: -1em;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 990px;
}

/* MEDIA QUERY */

@media (max-width: 1700px) {
    .homepg-contact-details {
        display: block;
    }
}

@media (max-width: 1460px) {
    .our-services-container {
        flex-direction: column;
        align-items: center;
    }

    .service-card-contents {
        margin-bottom: -4em;
    }
}

@media (max-width: 1400px) {

    .about-us-contents,
    .about-us-contents-aiden {
        width: 20em;
        height: 14em;
    }
}

@media (max-width: 1150px) {
    .about-us-container {
        flex-direction: column;
        margin-top: -4em;
    }

    .headshot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: auto;
    }

    .about-us-contents,
    .about-us-contents-aiden {
        box-sizing: border-box;
        width: 90%;
        margin: auto;
        height: auto;
    }

    .about-us-contents {
        margin-bottom: -3em;
    }

    .about-us-contents-aiden {
        margin-bottom: 1em;
    }

    .about-us-blurb-container {
        box-sizing: border-box;
        background-color: #0b0c0d;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        width: 84%;
        font-size: 1em;
        padding: 1em 2em 1em 2em;
        margin-top: 2em;
        margin-bottom: -2em;
        border-radius: 12px;
    }

    .line1 {
        margin-top: -1em;
    }

    .about-us-blurb-card {
        padding-right: 1em;
        margin-bottom: 0.5em;
    }
}

@media (max-height: 1099px) or (max-width: 1699px) {
    .logobgV2 {
        width: 350px;
        display: flex;
        float: right;
        margin-top: 8em;
        margin-right: 3.5em;
    }
}

@media (max-height: 800) or (max-width: 1699px) {
    .tag-container {
        font-size: 4em;
    }

    .home-serivce-locations-container {
        font-size: 1.2em;
    }
}

@media (max-width: 1099px) {

    /* Show the navigation menu if the viewport width is greater than or equal to 1099px */
    .nav-mobile-icon {
        display: flex;
        margin-right: -0.31em;
    }

    .nav-items {
        display: none;
    }

    .tag-container {
        font-size: 3em;
        margin-top: 0em;
    }

    .logobgV2 {
        display: inline;
        width: 15em;
        margin: 18em 0em 0em 2em;
        float: none;
    }

    .homepg-contact-details {
        font-size: 0.7em;
    }

    .home-serivce-locations-container {
        font-size: 1em;
    }

    /* .logo {
        display: none;
    } */

    .faq-card-contents {
        width: 85%;
        margin: auto;
    }

    .waterbg {
        width: 85.5%;
    }

    .faq-blurb {
        margin-top: -1em;
        margin-bottom: 1em;
        position: relative;
        z-index: 1
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    #contact-details-card {
        margin-top: 2em
    }

    #contact-form-card {
        margin-top: 0em;
    }

    .headshot {
        margin-top: 1em;
    }

    .see-our-services {
        font-size: 1.5em;
        margin-top: -1em;
    }
}

@media (max-width:1000px) {
    .about-us-blurb {
        font-size: 1.2em;
        line-height: 1.2em;
    }
}

@media (max-width: 630px) {
    .tag-container {
        margin-left: 0.6em;
    }

    .home-serivce-locations-container {
        display: none;
    }

    .homepg-contact-details {
        margin-left: 2em;
    }

    .logobgV2 {
        /* display: none; */
        margin-left: 0.5em;
    }

    .service-card-contents {
        width: 85%;
        margin: auto;
    }

    .brushbg {
        display: none;
        width: 85%;
    }

    .waterbg {
        display: none;
    }

    .our-services-blurb {
        margin-top: 0em;
        margin-bottom: 2em;

    }

    .our-services-heading {
        margin-bottom: 0.2em;
    }

    #our-services-card {
        margin-top: 2em;
    }

    #faq-card {
        margin-top: 0em;
    }
}

@media (max-width: 550px) {
    .headshot {
        width: 90%;
    }

    .our-services-heading {
        margin-top: 0.4em;
        margin-bottom: 0.5em;
    }

    .our-services-body {
        padding-bottom: 0.5em
    }

    .faq-heading {
        margin-top: 0.4em;
    }

    .faq-body {
        padding-bottom: 0.5em
    }

    .card-contents {
        width: 25em;
        margin: auto;
    }

    .our-deatils-body h2,
    .hours-heading,
    .serivce-locations-heading {
        font-size: 1.1em
    }

    .serivce-locations-container {
        float: none;
    }

    .serivce-locations-heading,
    .hours-heading {
        margin-left: 0.7em;
    }

    .hours-text,
    .days-text,
    .public-hols-text {
        padding-left: 0.8em;
    }

    .hours-text {
        padding-top: 0.5em;
    }

    .serivce-locations-text {
        padding-left: 1.8em;
        margin-top: -0.8em;
    }

    .our-deatils-body,
    form {
        padding-left: 0em;
        padding-top: 0em;
    }

    .contact-us-heading,
    .our-details-heading {
        margin-left: 0.4em;
        margin-top: 0.5em;
    }

    .hours-heading {
        margin-top: -0.5em;
    }
}

@media (max-width: 525px) {
    .page-heading {
        display: block;
        padding: 9em 2em 0em 2.1em;
    }

    .our-services-body,
    .faq-body {
        padding-left: 1em;
    }

    .our-services-heading,
    .faq-heading {
        margin-left: 0.6em
    }
}

@media (max-height: 570px) {
    .logobgV2 {
        display: none;
    }
}

@media (max-height: 480px) {
    .tag-container {
        margin-top: 0.2em;
    }
}


@media (max-width: 430px) {
    .card-contents {
        width: 20em;
        margin: auto;
    }
}

@media (max-width: 370px) {
    .tag-container {
        font-size: 2em;
        margin-top: 1em;
        margin-left: 0.6em;
    }

    .homepg-contact-details {
        margin-left: 1em;
        margin-bottom: -2.5em;
    }

    .our-deatils-body h2 {
        font-size: 1em;
    }

    .logobgV2 {
        width: 200px;
        margin-top: 15em;
        margin-left: 1.2em;
    }
}

@media (max-width: 340px) {
    .card-contents {
        width: 100%;
        margin: auto;
    }
}

@media (max-width: 280px) {
    .homepg-contact-details {
        font-size: 0.5em;
    }

    .tag-container {
        font-size: 1.5em
    }

    .logobgV2 {
        width: 150px;
        margin-top: 11em;
        margin-left: 1em;
    }
}









/* ANIMATIONS */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#modern {
    opacity: 0;
    animation: fadeIn 200ms ease-in 300ms forwards;
}

#traditional {
    opacity: 0;
    animation: fadeIn 200ms ease-in 600ms forwards;
}

#boat-building {
    opacity: 0;
    animation: fadeIn 200ms ease-in 900ms forwards;
}

.logobg {
    opacity: 0;
    animation: fadeIn 200ms ease-in 1200ms forwards;
}

.homepg-contact-details h2,
.home-serivce-locations-container {
    opacity: 0;
    animation: fadeIn 400ms ease-in 1500ms forwards;
}

#contact-form-card,
#our-services-card,
#contact-details-card,
#faq-card {
    opacity: 0;
    animation: fadeIn 400ms ease-in 200ms forwards;
}

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.see-our-services {
    opacity: 0;
    animation: slideInFromLeft 300ms ease-in 1500ms forwards;
    /* Adjust duration and delay as needed */
}

/* Mobile nav menu animation */
@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}